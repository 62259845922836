import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { learnSavings,
         learnPeer2Peer,
         learnEmployment,
         learnUsersStorage,
         learnROSCA,
         learnROSCAContract,
         learnFees,
         learnMediatorFees,
         learnDelete,
         learnCCIP} from './learnConfig';

const HowTo = () => {
  // Use white-space: pre-line; to preserve line breaks
 const textWithLineBreaks = { whiteSpace: 'pre-line' };

  return (
    <Container>
      <h1>Questions and Answers</h1>

      {/* ROSCA Explanation */}
      <section>
        <h2>What is a ROSCA?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnROSCA}</p>
          </Col>
        </Row>
      </section>

      {/* ROSCA Contract Explained */}
      <section>
        <h2>How does the ROSCA smart contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnROSCAContract}</p>
          </Col>
        </Row>
      </section>

      {/* Savings */}
      <section>
        <h2>How does the savings contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnSavings}</p>
          </Col>
        </Row>
      </section>

      {/* Peer to Peer */}
      <section>
        <h2>How does a peer to peer financial agreement work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnPeer2Peer}</p>
          </Col>
        </Row>
      </section>

      {/* CCIP */}
      <section>
        <h2>What is the burn and mint CCIP?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnCCIP}</p>
          </Col>
        </Row>
      </section>

      {/* Employment */}
      <section>
        <h2>How are the employment contracts structured?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnEmployment}</p>
          </Col>
        </Row>
      </section>

      {/* Users Storage */}
      <section>
        <h2>How does the the users database contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnUsersStorage}</p>
          </Col>
        </Row>
      </section>

      {/* Mediators */}
      <section>
        <h2>How do mediator fees work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnMediatorFees}</p>
          </Col>
        </Row>
      </section>

      {/* Charging? */}
      <section>
        <h2>Does eVow.io charge fees for their services?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnFees}</p>
          </Col>
        </Row>
      </section>

      {/* Contract Deletion */}
      <section>
        <h2>Can I delete a smart contract?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnDelete}</p>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default HowTo;
