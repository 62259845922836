import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link , useNavigate} from 'react-router-dom'; // Import Link from react-router-dom for navigation
import { FaArrowLeft, FaHome } from 'react-icons/fa'; // Import the desired icons from react-icons

const About = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  return (
    <div className="pl-5 pr-5">
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
          <Link to="/">
            <button className="profile-button">
              <FaHome style={{ fontSize: '24px'}}/> Home
            </button>
          </Link>
        </div>
      </Col>
    </Container>
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <div className="about-container">
            <section className="intro-section">
              <h2>Discover the Power of eVow</h2>
              <p className="lead">
                Your Gateway to Smart Contracts and Financial Freedom
              </p>
              <p>
                Welcome to the world of eVow, where innovation, security, and financial freedom converge. EVOW token is your digital key to unlocking the limitless potential of blockchain-based smart contracts. With us, you're not just a user; you're a pioneer in the decentralized financial frontier.
              </p>
              <p>
                Say goodbye to complicated financial agreements and hello to a simpler, smarter way of managing your finances. Whether you're an individual, a business, or an innovator, eVow has something extraordinary in store for you.
              </p>
              <p>
                Ready to embark on this thrilling journey? Signing up is easy and free via your trusted Metamask wallet. But that's just the beginning. Subscribed members enjoy exclusive access to advanced smart contracts and secure friend address storage, all while we prioritize your safety with top-notch security measures.
              </p>
            </section>

            <section className="tokenomics-section">
              <h2>The EVOW Token Economy</h2>
              <p className="lead">Total Supply: 25 Million Tokens</p>

              <div className="distribution-list">
                <div className="distribution-item">
                  <p className="lead">Fueling the Future: Smart Contract Development and Marketing Fund</p>
                  <p>
                    48% (12 Million Tokens)
                    <br />
                    These tokens are the lifeblood of our future enhancements, developments, token pools, and marketing initiatives. We're committed to supporting the growth, innovation, and promotion of our project.
                  </p>
                </div>

                <div className="distribution-item">
                  <p className="lead">Honoring Our Heroes: Core Developers and Initial Funding Participants</p>
                  <p>
                    30% (7.5 Million Tokens)
                    <br />
                    This share is reserved for the champions who pioneered our project. It's a token of our appreciation for their unwavering dedication and invaluable contributions.
                  </p>
                </div>

                <div className="distribution-item">
                  <p className="lead">Seize the Moment: Presale</p>
                  <p>
                    15% (3.75 Million Tokens)
                    <br />
                    Get ahead of the curve by participating in our presale. You're not just investing; you're shaping our future and securing tokens before our grand launch.
                  </p>
                </div>

                <div className="distribution-item">
                  <p className="lead">Community First: Airdrop and Presale Bonus</p>
                  <p>
                    7% (1.75 Million Tokens)
                    <br />
                    We cherish our community. That's why we're giving back through airdrops and bonuses in different project phases. It's our way of saying thank you for being with us from the start.
                  </p>
                </div>
              </div>
            </section>

            <section className="usage-section">
              <h3>Your Token, Your Power: EVOW Token Utility</h3>
              <p>
                EVOW token is more than just a cryptocurrency; it's your ticket to a world of smart contracts powered by Polygon and Avalanche blockchains. Our EVM-based smart contracts offer unmatched security and transparency.
              </p>
              <p>
                Dive into our diverse contract categories, including Banking, Employment, Peer-to-Peer, Record Storage, and more. Subscribed users enjoy exclusive access to Polygon (MATIC), USDC and WBTC token contracts, offering stability and security in volatile markets. Furthermore, subscribed users also enjoy usage of the Avalanche (AVAX) as a deployment option along with Avalanche registered USDC and WBTC tokens. Lastly, users can exchange EVOW token between the Polygon and Avalanche blockchains via our burn and mint CCIP; which allows us to ensure the amount of EVOW tokens are correctly maintained.
              </p>
            </section>

            <section className="subscription-section">
              <h3>Your Subscription, Your Impact</h3>
              <p>
                Your subscription to eVow.io isn't just a membership; it's an investment in the future. Your subscription fees are distributed to ensure the sustainability and growth of our project:
              </p>
              <div className="distribution-list">
                <div className="distribution-item">
                  <p className="lead">Empowering Administrators</p>
                  <p>
                    30%
                    <br />
                    This share goes to our dedicated platform administrators, honoring their role in maintaining and enhancing our ecosystem.
                  </p>
                </div>

                <div className="distribution-item">
                  <p className="lead">Fueling Development</p>
                  <p>
                    40%
                    <br />
                    We allocate this portion to the ongoing development, innovation, and expansion of our smart contracts and the platform as a whole.
                  </p>
                </div>

                <div className="distribution-item">
                  <p className="lead">Burning Bright</p>
                  <p>
                    30%
                    <br />
                    A portion of your subscription fees goes towards burning tokens, gradually reducing the token supply. This deflationary mechanism enhances scarcity and may contribute to the token's long-term value.
                  </p>
                </div>
              </div>
            </section>

            <p className="disclaimer">
              <strong>Disclaimer:</strong> Our tokenomics design is a careful balance, incentivizing participation, rewarding contributors, promoting growth, and maintaining a deflationary mechanism. It's designed to cater to different phases of our project, from development to community engagement and adoption. Join us on this exciting journey as we shape the future of finance together.
            </p>
         </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default About;
