import React, { useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import Web3 from 'web3';
import { standardERC20ABI } from './abiConfig';
import { polygonMainUrl } from './urlConfig';
import { siteTokenMATIC, wbtcTokenAddressMATIC, usdcTokenAddressMATIC, chainId, masterControllerFixABI, masterControllerFixAddress } from './abiConfig'
import { chainPrompt } from './chainPrompt';

const PurchaseModal = ( { show, onClose } ) => {
  const [step, setStep] = useState(1);
  const [successText, setSuccessText] = useState('');
  const [selectedPurchaseToken, setSelectedPurchaseToken] = useState('MATIC');
  const [expectedPurchaseQty, setExpectedPurchaseQty] = useState(10);
  const [quantity, setQuantity] = useState(1)
  const [wbtcPrice, setWBTCPrice] = useState(0.000012)
  const [usdPrice, setUSDPrice] = useState(0.65)
  const [maticPrice, setMATICPrice] = useState(1.4)
  const walletAddress = localStorage.getItem("walletAddress");
  const providerType = localStorage.getItem('provider');
  const buttonText = step === 1 ? 'Cancel' : 'Close';

  const handleClose = () => {
    // Reset the step back to 1 when the modal is closed
    setStep(1);
    onClose(); // Call the onClose callback
  };

  const handleTokenChange = (event) => {
    setSelectedPurchaseToken(event.target.value);
  };

  const handleBlur = () => {

    // The onBlur event can perform other tasks or validations
    if (selectedPurchaseToken === 'MATIC'){
      setExpectedPurchaseQty(parseFloat(quantity * maticPrice));
    }
    else if (selectedPurchaseToken === 'USDC'){
      setExpectedPurchaseQty(parseFloat(quantity * usdPrice));
    }
    else if (selectedPurchaseToken === 'WBTC'){
      setExpectedPurchaseQty(parseFloat(quantity * wbtcPrice));
    }
  };

  const handleAmountChange = (event) => {
    setQuantity(parseFloat(event.target.value));

    if (selectedPurchaseToken === 'MATIC'){
      setExpectedPurchaseQty(parseFloat(quantity * maticPrice));
    }
    else if (selectedPurchaseToken === 'USDC'){
      setExpectedPurchaseQty(parseFloat(quantity * usdPrice));
    }
    else if (selectedPurchaseToken === 'WBTC'){
      setExpectedPurchaseQty(parseFloat(quantity * wbtcPrice));
    }
  };

  const handleArrowKeys = (event) => {
    event.preventDefault(); // Prevent the default behavior of arrow keys

    if (event.key === 'ArrowUp') {
      setQuantity((prevQuantity) => (parseFloat(prevQuantity) + 1).toString());
    } else if (event.key === 'ArrowDown') {
      setQuantity((prevQuantity) => (parseFloat(prevQuantity) - 1).toString());
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  // Split the text into lines and create an array of React elements
  const textLines = successText.split('<br>').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  const handlePurchase = async () => {

    const switchChain = await chainPrompt("Polygon", providerType);

    let tokenContract;
    let promptUserToInstall = false;
    let provider;

    try {

      setSuccessText(`Please don't leave the page. We are building the transaction and acquiring the gas cost. You will see a pop up from your wallet provider in a brief moment.`);
      setStep(2)

      if (!window.ethereum || !window.ethereum.request) {
        // Handle case where Metamask is not available
        return;
      }

      if (providerType === 'coinbase') {
        try{
          provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
        } catch(e){
          provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
        }
      }

      if (providerType === 'metamask') {
        try{
          provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
        } catch(e){
          provider = window.ethereum.isMetaMask ? window.ethereum : null;
        }
      }

      if (providerType === 'walletConnect') {

        try{
          provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
        } catch(e){
          provider = window.ethereum.isWalletConnect ? window.ethereum : null;
        }
      }

      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      const siteAddress = accounts[0]

      if (siteAddress !== walletAddress){
        throw new Error('Wallet addresses do not match');
      }

      if (selectedPurchaseToken === "MATIC"){

        const web3 = new Web3(polygonMainUrl)
        const masterInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        //tokenContract = new web3.eth.Contract(standardERC20ABI, siteTokenMATIC);

        const amountValue = web3.utils.toWei(quantity.toString());
        const weiValue = web3.utils.toWei(expectedPurchaseQty.toString());

        // Create a transaction object
        const transactionObject = {
          from: siteAddress,
          to: masterInstance.options.address,
          data: masterInstance.methods.PresalePurchase(amountValue).encodeABI(),
          value: web3.utils.toHex(weiValue),
        };

        console.log("Object: ", transactionObject);

        let gasEstimate = await web3.eth.estimateGas(transactionObject);

        console.log("Gas Estimate: ", gasEstimate);

        let gasPrice = await web3.eth.getGasPrice();
        let gasFee = gasEstimate * 3

        const newTransactionObj = {
          ...transactionObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        //Send to user via eth_sendTransaction request with parameters
        const tx = await provider.request({
          method: 'eth_sendTransaction',
          params: [newTransactionObj]
        });

        setSuccessText(`TX: ${tx}<br><br>Congratulations on your purchase!<br><br>You can use your tokens to subscribe or interact with contracts on our network. You will have immediate access to your tokens via your Metamask wallet. If you have not already imported the EVOW token, you can do so with the token address. <br><br>Here is the EVOW token address:<br>${siteTokenMATIC}`);
      }
      else if (selectedPurchaseToken === 'USDC'){

        const web3 = new Web3(polygonMainUrl)
        const masterInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        tokenContract = new web3.eth.Contract(standardERC20ABI, usdcTokenAddressMATIC);

        //Retrieve the _amount input from inputValues
        const _amount = web3.utils.toWei(quantity.toString())
        //const _mweiPurchaseQty = web3.utils.toWei(expectedPurchaseQty.toString(), 'mwei');

        const _mweiPurchaseQtyMath = Math.round(expectedPurchaseQty * 10 ** 6);
        const _mweiPurchaseQty = _mweiPurchaseQtyMath.toString();

        const approveData = tokenContract.methods.approve(masterControllerFixAddress, _mweiPurchaseQty).encodeABI();

        const approveTransactionObject = {
          from: siteAddress,
          to: tokenContract.options.address,
          data: approveData,
        };

        let gasEstimate = await web3.eth.estimateGas(approveTransactionObject);
        let gasPrice = await web3.eth.getGasPrice();
        let gasFee = gasEstimate * 2


        // Now, you can construct and send your transaction with the calculated gas values
        const newApprovalTransaction = {
          ...approveTransactionObject,
          gas: web3.utils.toHex(gasFee), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(gasPrice), // Use the current gas price
        };

        setSuccessText("Transaction Approval 1/2");

        //Send to user via eth_sendTransaction request with parameters
        const approveTransactionHash = await provider.request({
          method: 'eth_sendTransaction',
          params: [newApprovalTransaction]
        });

        setSuccessText("Transaction Receipt 1/2");

        // Poll every 2 seconds for the transaction receipt
        const checkInterval = 3000; // 2 seconds
        let receipt = null;
        while (receipt === null) {
          await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
            // Check for the receipt
          receipt = await web3.eth.getTransactionReceipt(approveTransactionHash);
        }

        setSuccessText("Building Transaction 2/2");

        // Create a transaction object
        const transactionObject = {
          from: siteAddress,
          to: masterControllerFixAddress,
          data: masterInstance.methods.PresalePurchaseToken(_amount.toString(), _mweiPurchaseQty, selectedPurchaseToken).encodeABI(),
        };

        gasEstimate = await web3.eth.estimateGas(transactionObject);
        gasPrice = await web3.eth.getGasPrice();
        gasFee = gasEstimate * 2

        const newTransactionObj = {
          ...transactionObject,
          gas: web3.utils.toHex(gasFee), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(gasPrice),
        };

        //Send to user via eth_sendTransaction request with parameters
        const tx = await provider.request({
          method: 'eth_sendTransaction',
          params: [newTransactionObj]
        });

        setSuccessText(`TX: ${tx}<br><br>Congratulations on your purchase!<br><br>You can use your tokens to subscribe or interact with contracts on our network. You will have immediate access to your tokens via your Metamask wallet. If you have not already imported the EVOW token, you can do so with the token address. <br><br>Here is the EVOW token address:<br>${siteTokenMATIC}`);
  }
      else if(selectedPurchaseToken === 'WBTC'){

        const web3 = new Web3(polygonMainUrl)
        const masterInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        tokenContract = new web3.eth.Contract(standardERC20ABI, wbtcTokenAddressMATIC);

        //Retrieve the _amount input from inputValues
        const _amount = web3.utils.toWei(quantity.toString())

        const _weiPurchaseQtyMath = Math.round(expectedPurchaseQty * 10 ** 8);
        const _weiPurchaseQty = _weiPurchaseQtyMath.toString();

        const approveData = tokenContract.methods.approve(masterControllerFixAddress, _weiPurchaseQty).encodeABI();

        const approveTransactionObject = {
          from: siteAddress,
          to: tokenContract.options.address,
          data: approveData,
        };

        let gasEstimate = await web3.eth.estimateGas(approveTransactionObject);
        let gasPrice = await web3.eth.getGasPrice();
        let gasFee = gasEstimate * 2

        // Now, you can construct and send your transaction with the calculated gas values
        const newApprovalTransaction = {
          ...approveTransactionObject,
          gas: web3.utils.toHex(gasFee), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(gasPrice), // Use the current gas price
        };

        setSuccessText("Transaction Approval 1/2");

        //Send to user via eth_sendTransaction request with parameters
        const approveTransactionHash = await provider.request({
          method: 'eth_sendTransaction',
          params: [newApprovalTransaction]
        });

        setSuccessText("Transaction Receipt 1/2");

        // Poll every 2 seconds for the transaction receipt
        const checkInterval = 3000; // 2 seconds
        let receipt = null;
        while (receipt === null) {
          await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
            // Check for the receipt
          receipt = await web3.eth.getTransactionReceipt(approveTransactionHash);
        }

        setSuccessText("Building Transaction 2/2");

        // Create a transaction object
        const transactionObject = {
          from: siteAddress,
          to: masterControllerFixAddress,
          data: masterInstance.methods.PresalePurchaseToken(_amount.toString(), _weiPurchaseQty, selectedPurchaseToken).encodeABI(),
        };

        gasEstimate = await web3.eth.estimateGas(transactionObject);
        gasPrice = await web3.eth.getGasPrice();
        gasFee = gasEstimate * 2

        const newTransactionObj = {
          ...transactionObject,
          gas: web3.utils.toHex(gasFee), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(gasPrice),
        };

        //Send to user via eth_sendTransaction request with parameters
        const tx = await provider.request({
          method: 'eth_sendTransaction',
          params: [newTransactionObj]
        });

        setSuccessText(`TX: ${tx}<br><br>Congratulations on your purchase!<br><br>You can use your tokens to subscribe or interact with contracts on our network. You will have immediate access to your tokens via your Metamask wallet. If you have not already imported the EVOW token, you can do so with the token address. <br><br>Here is the EVOW token address:<br>${siteTokenMATIC}`);
      }

  } catch (error) {
      // Handle error occurred during wallet connection or API request
      // Implement your own error handling logic here
      setSuccessText(`Error: ${error}`)
      setStep(1)
  }
};

  return(
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Purchase EVOW Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
      {step === 1  &&(
        <Row>
          <Col>
          <p>You are purchasing EVOW tokens. Please remember that EVOW utilizes the Polygon chain.  USDC, WBTC and other payment tokens will need to be on the chain.</p>
            <br/>
            <p>Current Pricing:
            <br />
            1 EVOW = {maticPrice} MATIC
            <br />
            1 EVOW = {usdPrice} USDC
            <br />
            1 EVOW = {wbtcPrice} WBTC
            </p>
            <p>
              <label>
                <input
                  type="radio"
                  name="purchaseToken"
                  value="MATIC"
                  checked={selectedPurchaseToken === "MATIC"}
                  onChange={handleTokenChange}
                  onBlur={handleBlur}
                />
                MATIC
              </label>

              <br />

              <label>
                <input
                  type="radio"
                  name="purchaseToken"
                  value="USDC"
                  checked={selectedPurchaseToken === "USDC"}
                  onChange={handleTokenChange}
                  onBlur={handleBlur}
                />
                USDC
              </label>

              <br />

              <label>
                <input
                  type="radio"
                  name="purchaseToken"
                  value="WBTC"
                  checked={selectedPurchaseToken === "WBTC"}
                  onChange={handleTokenChange}
                  onBlur={handleBlur}
                />
                WBTC
              </label>

              <br />

              Enter Purchase Quantity:
                <input
                  type="number"
                  name="amountText"
                  value={quantity}
                  onChange={handleAmountChange}
                  onBlur={handleBlur}
                  step = '1'
                  className="black-text" // Apply the CSS class
                />

              <br />
            </p>
              <p>

              Payment Token: {selectedPurchaseToken}

              <br />

              Expected Payment Amount: {expectedPurchaseQty}

              </p>
            <p>Note: Pricing is subject to change.</p>

            <p>By purchasing, you agree to our <a style={{color: 'blue'}} href="/terms">Terms and Conditions</a> and <a style={{color: 'blue'}} href="/privacy">Cookies and Privacy Policy</a>.</p>
          </Col>
        </Row>
      )}

      {step === 2 &&(
        <Row>
          <Col>
            <p style={{ wordWrap: 'break-word', maxWidth: '100%'}}>
              {textLines}
            </p>
          </Col>
        </Row>
      )}

      </div>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        {buttonText}
      </Button>
        {step === 1 && (
        <Button variant="primary" onClick={handlePurchase}>
          Purchase
        </Button>
      )}
      </Modal.Footer>
    </Modal>
);

};

export default PurchaseModal;
