import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, { useState} from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import PolygonPrimary from './images/polygonPrimary.png';
import CoinbaseLogo from './images/coinbase_logo.png';
import Cbwallet from './images/cbwallet.png';
import MetamaskLogo from './images/metamask.svg';
import AvalancheLong from './images/avalanche_long.png';
import MatchaLogo from './images/matchaLogo.png';
import TraderLogo from './images/trader.jpg';
import ButtonBK from './images/bkchain.png'
import PolygonWhite from './images/PolygonWhite.png'
import { FaTwitter, FaTelegram} from 'react-icons/fa';
import { RiQuestionAnswerLine } from "react-icons/ri";
import { SiSololearn } from "react-icons/si";
import { Container, Row, Col } from "react-bootstrap";
import { IoLogoBitcoin } from "react-icons/io";
import CountComponent from './countComponent'
import PurchaseModal from './purchase';
import Roadmap from './roadmap'

const Home = () => {

  const [showModals, setShowModals] = useState({
   PurchaseModal: false,
 });


 const handleModalClick = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: true,
   });
 };

 const handleCloseModal = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: false,
   });
 };

 const modalComponents = [
   {
     name: 'PurchaseModal',
     component: PurchaseModal,
   },
   // Add more modal components here
 ];

 const handlePurchaseClick = () => {
   setShowModals((prevShowModals) => ({
     ...prevShowModals,
     PurchaseModal: true,
   }));
 };

  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  const outlinedColumnStyleTwo = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
  };
  const buttonColumn = {
    border: '2px solid #000000', // Adjust border style as needed
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '2px', // Add margin on all sides
    background: `url(${ButtonBK})`
  };
  const buttonStyle = {
      backgroundColor: '#cc5500', // Burnt orange color
      color: 'white',
      padding: '25px 40px', // Adjust padding for elongation
      borderRadius: '30px', // Rounded edges
      fontSize: '18px', // Font size
      width: '100%', // Full width
      maxWidth: '600px', // Maximum width like a banner
      textAlign: 'center',
      border: 'none',
      cursor: 'pointer',
      margin: '20px auto', // Center the button
      display: 'block',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for 3D effect
      transition: 'transform 0.2s', // Smooth transition for hover effect
    };
  return (
    <div className="pl-5 pr-5">
    {modalComponents.map((modal) => (
      <modal.component
        key={modal.name}
        show={showModals[modal.name]}
        onClose={() => handleCloseModal(modal.name)}
      />
    ))}
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <h1 style={{textAlign: 'center'}}>Finance | Peer to Peer | Employment | Sales | Data Exchange</h1>
          <p className="lead">Experience the Future of Finance, Peer-to-Peer Transactions, Employment Agreements, Sales Contracts, and More with eVow.io. Take control of your financial destiny with our intuitive platform, where you can seamlessly enter into credit smart contracts or forge friendly peer-to-peer financial agreements.</p>
          <p className="lead">If you're looking to hire a contractor or sell an item remotely, we've got you covered! You own the contract. You deploy it. We'll help you through the entire process.</p>
          <p className="lead">Join us in revolutionizing the accessibility of Web3, making advanced blockchain technology accessible and user-friendly for both corporate entities and retail users alike. Experience the future of finance with eVow.io today</p>
        </Col>
      </Row>
      <Row style={buttonColumn}>
        <Col style={{textAlign: 'center'}}>
          <img style={{ width: '40%', height: 'auto'}} src={MetamaskLogo} alt="Metamask Logo" />
          <img style={{ width: '30%', height: 'auto'}} src={Cbwallet} alt="Metamask Logo" />
        </Col>
        <Col>
          <button onClick= {handlePurchaseClick} style={buttonStyle}>
            Buy EVOW
          </button>
          <br/>
          <p  style={{textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: '24px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}> CONNECT WALLET</p>
        </Col>
        <Col>
          <img style={{ width: '30%', height: 'auto'}} src={PolygonWhite} alt="Polygon Logo in field of White" />
        </Col>
      </Row>
      <Row>
        <Col style={outlinedColumnStyle}>
          <h1 >EVOW Tokenomics</h1>
          <p className="lead">Total Supply: 25 Million EVOW</p>
          <p className="lead">Burn Mechanism via Subscriptions</p>
          <p className="lead">Airdrop and Presale Bonus: 7% (1.75 Million EVOW with further break down as 1.25 Mil Presale Bonus & 500K Airdrop)</p>
          <p className="lead">Smart Contract Development, Pool Funding and Marketing Fund: 48% (12 Million EVOW)</p>
          <p className="lead">Burn and Mint CCIP Bridge for Polygon and Avalanche blockchains</p>
          <Link to="/tokenomics">
            <button className="profile-button">
              <SiSololearn style={{ fontSize: '24px'}}/> Learn More
            </button>
          </Link>
        </Col>
        <Col style={outlinedColumnStyle}>
          <Row>
            <h2 >Join Our Community</h2>
            <p className="lead">
            <Link to="https://twitter.com/eVowIO">
              <FaTwitter style={{ fontSize: '48px', color: '#b103fc'}}/>
            </Link>
            <Link to="https://t.me/OfficialEvowDiscussion">
              <FaTelegram style={{ fontSize: '48px', color: '#b103fc'}}/>
            </Link>
            </p>
          </Row>
          <Row>
            <h2 >Q & A - Deployable Contracts</h2>
            <p className="lead">
            <a href="/how" className="black-icon-link">
              <RiQuestionAnswerLine style={{ fontSize: '48px' }}/>
            </a>
            </p>
          </Row>
            <Col>
              <h2>Learn about Polygon and Avalanche</h2>
              <p className="lead">
              <a href="https://polygon.technology/">
                <img style={{ width: '30%', height: '30%', marginRight: '20px'}} src={PolygonPrimary} alt="Polygon Primary Logo" />
              </a>
              <a href="https://www.avax.network/">
                <img style={{ width: '30%', height: '30%'}} src={AvalancheLong} alt="Avalanche Primary Logo" />
              </a>
              </p>
            </Col>
            <Col>
              <h2>Buy Polygon and Avalanche on Coinbase!</h2>
              <p className="lead">
              <a href="https://coinbase.com/" >
                <img style={{ width: '25%', height: '25%' }} src={CoinbaseLogo} alt="Coinbase Logo" />
              </a>
              </p>
            </Col>
            <Col>
              <h2>Support decentralization! Purchase MATIC or AVAX from a DEX!</h2>
              <p className="lead">
              <a href="https://matcha.xyz/" >
                <img style={{ width: '25%', height: '25%', marginRight: '20px'}} src={MatchaLogo} alt="Matcha" />
              </a>
              <a href="https://traderjoe.xyz/" >
                <img style={{ width: '23%', height: '23%' }} src={TraderLogo} alt="Trader Joe" />
              </a>
              </p>
            </Col>
        </Col>
      </Row>

      <Row>
        <Col style={outlinedColumnStyle}>
          <h1 >Airdrop Allocation</h1>
          <p className="lead">
            We've designated 2% of our funds for an exclusive airdrop program,
            consisting of 500K tokens. Eligible users of our platform
            will have the opportunity to receive these tokens. The allocation
            will be determined based on various criteria, including testnet user
             status, user profile completeness, subscription status, active contracts and successful
            contract deployments.
          </p>
          <p className="lead">Date: To Be Announced</p>

          <h1 >Pre-Sale and Bonus</h1>
          <p className="lead">
            A portion of our funds, precisely 15%, are reserved for our pre-sale
            phase, totaling 3.75 million tokens. An additional 5% of the funds
            are set aside for a pre-sale bonus, amounting to 1.25 million tokens.
            As a special offer, users purchasing presale funds will be given an
            additional one thirds of their purchase amount in bonus tokens from the
            pre-sale fund.
          </p>
          <p className="lead">Pre-sale Stages:</p>
          <ul>
            <li>Stage One: 750K Tokens</li>
            <li>Stage Two: 1.125 Million Tokens</li>
            <li>Stage Three: 1.875 Million Tokens</li>
          </ul>
          <p className="lead">
            If a stage is complete before all tokens have been purchased, any
            remaining tokens will be moved to the development fund.
          </p>
          <p className="lead">Date: On-going Until Complete</p>
          <p className="lead">
          <IoLogoBitcoin  style={{ fontSize: '24px', color: 'gold' }}/>
          Please create a profile to gain access to purchasing.
          <IoLogoBitcoin  style={{ fontSize: '24px', color: 'gold' }}/>
          </p>
        </Col>
      </Row>
      <Roadmap/>
    </Container>
    </div>
  );
};

export default Home;
