import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, {useContext} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { AuthContext } from './authProvider';
import { SubscribedContext } from './subscribedProvider';
import { FaArrowLeft, FaHome, FaUser, FaTelegram } from 'react-icons/fa'; // Import the desired icons from react-icons
import { BiSolidLogOutCircle } from 'react-icons/bi';
import { MdOutlineEmail } from "react-icons/md";
import { Link, useNavigate} from 'react-router-dom'; // Import Link from react-router-dom for navigation

const Contact = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const { unsubscribe } = useContext(SubscribedContext);
  const { logout } = useContext(AuthContext);
  const emailAddress = "info@evow.io"; // Replace with the actual email address
  const subject = "eVow.io Inquiry"; // Replace with the desired email subject
  const body = ""; // Replace with the desired email body

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
    overflowWrap: 'break-word'
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLogOff = () => {
    logout();
    unsubscribe();
    navigate('/');
  }

  return (
    <div className="pl-5 pr-5">
    <Container>
      { isLoggedIn ? (
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
            <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
          </button>
          <Link to="/user">
            <button className="profile-button" style={{ marginRight: '10px' }} >
              <FaUser style={{ fontSize: '24px'}}/> Profile
            </button>
          </Link>
          <button className="back-button" style={{ marginRight: '10px' }} onClick={handleLogOff}>
            <BiSolidLogOutCircle  style={{ fontSize: '24px'}} /> Logout
          </button>
        </div>
      </Col>
    ) : (
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
            <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
          </button>
          <Link to="/">
            <button className="profile-button">
              <FaHome style={{ fontSize: '24px'}}/> Home
            </button>
          </Link>
        </div>
      </Col>
    )}
    </Container>
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <h3>Thank you</h3>
          <p>
            Please contact us via our Telegram channel. Our representatives will be available to help with your request.
          </p>
          <p>
          <Link to="https://t.me/eVowContracts">
            <button className="profile-button">
              <FaTelegram style={{ fontSize: '24px'}}/> Telegram
            </button>
          </Link>
          <a href={mailtoLink} style={{ paddingLeft: '4px' }}>
            <button className="profile-button">
              <MdOutlineEmail style={{ fontSize: '24px' }} /> Email
            </button>
          </a>
          </p>

        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Contact;
