import React, { useState, useContext } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import WalletModal from './loginModal';
import AgeModal from './AgeVerificationModal';
import { AgeContext } from './ageProvider';
import { FaCheck, FaTimes } from 'react-icons/fa';

function Login() {
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
    overflowWrap: 'break-word'
  };
  const { isAge, Aged, unAge } = useContext(AgeContext);
  const [showModals, setShowModals] = useState({
   AgeModal: false,
   WalletModal: false,
   // Add more modal flags here if needed
 });
 const modalComponents = [
   {
     name: 'AgeModal',
     component: AgeModal,
   },
   {
     name: 'WalletModal',
     component: WalletModal,
   },
 ];

  const handleModalClick = (modalName) => {
    setShowModals({
      ...showModals,
      [modalName]: true,
    });
  };

  const handleCloseModal = (modalName) => {
    setShowModals({
      ...showModals,
      [modalName]: false,
    });
  };

  const handleWalletLoginClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      WalletModal: true, // Show the oneVOne modal
    }));
  };

  const handleNoAge = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      AgeModal: true, // Show the oneVOne modal
    }));
  };

  const freeSubscription = {
    intermediaryFee: '10%',
    accessToAdvancedContracts: false,
    accessToMoreTokens: false,
    bridgeUsage: false,
  };

  const paidSubscription = {
    intermediaryFee: '10%',
    accessToAdvancedContracts: true,
    accessToMoreTokens: true,
    bridgeUsage: true,
  };

  const renderCheckmark = (value) => {
    return value ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />;
  };

  return (
    <div>
      {modalComponents.map((modal) => (
        <modal.component
          key={modal.name}
          show={showModals[modal.name]}
          onClose={() => handleCloseModal(modal.name)}
        />
      ))}

      {isAge === true && (
          <Container>
          <Row>
            <Col style={outlinedColumnStyle}>
              <Row>
                <h2>Welcome</h2>
              </Row>
              <Row>
                <p>Sign up is free and anonymous!</p>
              </Row>
              <Row>
                <p>We're very excited you're looking to sign up and start a web3 journey with us! If you have any questions or need assistance, please contact us via Telegram. We're ready to help.</p>
              </Row>
              <Row>
                <button className="btn btn-primary btn-sm" style={{ width: '200px' }} onClick={handleWalletLoginClick}>
                  Login/Sign Up
                </button>
                <br />
              </Row>
            </Col>
            <Col style={outlinedColumnStyle}>
              <h2>Subscription Comparison</h2>
              <p>Must be 18 years of age or older to sign up for a free or paid account.</p>
              <table className="subscription-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Free</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td  style={{ overflowWrap: 'break-word' }} >Intermediary Fee</td>
                    <td>{freeSubscription.intermediaryFee}</td>
                    <td>{paidSubscription.intermediaryFee}</td>
                  </tr>
                  <tr>
                    <td>Amount of Friend Addresses</td>
                    <td>6</td>
                    <td>Unlimited</td>
                  </tr>
                  <tr>
                    <td  style={{ overflowWrap: 'break-word' }} >Access to Advanced Contracts</td>
                    <td>{renderCheckmark(freeSubscription.accessToAdvancedContracts)}</td>
                    <td>{renderCheckmark(paidSubscription.accessToAdvancedContracts)}</td>
                  </tr>
                  <tr>
                    <td  style={{ overflowWrap: 'break-word' }} >Access to More Tokens</td>
                    <td>{renderCheckmark(freeSubscription.accessToMoreTokens)}</td>
                    <td>{renderCheckmark(paidSubscription.accessToMoreTokens)}</td>
                  </tr>
                  <tr>
                    <td  style={{ overflowWrap: 'break-word' }} >Free Polygon/Avalanche Bridge Conversion (Limited Time)</td>
                    <td>{renderCheckmark(freeSubscription.bridgeUsage)}</td>
                    <td>{renderCheckmark(paidSubscription.bridgeUsage)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}

      {isAge === false && (
        <Container>
          <AgeModal show={true} onClose={() => handleCloseModal("AgeModal")} />
        </Container>
      )
    }
      <script src="https://unpkg.com/@chainapsis/keplr@0.41.2/dist/keplr.min.js"></script>
      <script scr="https://www.npmjs.com/package/@cosmjs/stargate"></script>
    </div>
  );
}

export default Login;
