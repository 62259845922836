import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
    standardERC20ABI,
    masterControllerABI,
    masterControllerAddress,
    masterControllerBytecode,
    masterControllerFixABI,
    masterControllerFixBytecode,
    masterControllerFixAddress,
    siteTokenMATIC
                    } from './abiConfig';

import { polygonMainUrl } from './urlConfig';

const AdminPanel = () => {
  const [contract, setContract] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [viewData, setViewData] = useState({});

  const walletAddress = localStorage.getItem('walletAddress');

  const web3 = new Web3(polygonMainUrl);

  const handleInputChange = (name, value) => {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleFixInputChange = (name, value) => {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const outlinedColumnStyle = {
    border: '2px solid #a60f85', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#fbd7fc',
  };

  const handleFunctionCall = async (func) => {
    let web3 = new Web3(polygonMainUrl);

    try {
      if (func.stateMutability === 'view' || func.stateMutability === 'pure') {

        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);
        // Prepare the arguments for the function call
        let argValues = [];
        if (func.inputs && func.inputs.length > 0) {
          argValues = func.inputs.map(input => inputValues[input.name] || '');
        }

        // Call the function
        const result = await contractInstance.methods[func.name](...argValues).call();

        setViewData({
          ...viewData,
          [func.name]: result,
        });

      } else if (func.name === "FundContract"){
        web3 = new Web3(polygonMainUrl);
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here

        console.log("FUNDING...")
        const tokenContract = new web3.eth.Contract(standardERC20ABI, siteTokenMATIC);

        console.log("token", tokenContract);
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);
        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];
        console.log(web3)

        const weiValue = web3.utils.toWei(_amount.toString()); // Equivalent to 'ether'
        console.log(weiValue);
        const approveData = tokenContract.methods.approve(masterControllerAddress, weiValue).encodeABI();

        const approveTransactionObject = {
          from: walletAddress,
          to: tokenContract.options.address,
          gas: web3.utils.toHex(6000000),
          gasPrice: web3.utils.toHex(web3.utils.toWei('30', 'gwei')),
          data: approveData,
        };
        const approveTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [approveTransactionObject]
        });

        const sendData = contractInstance.methods[func.name](weiValue).encodeABI();

        const sendTransactionObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          gas: web3.utils.toHex(1300000),
          gasPrice: web3.utils.toHex(web3.utils.toWei('30', 'gwei')),
          data: sendData,
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const sendTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [sendTransactionObject]
        });

        // Handle the transaction hash or receipt as needed
        // Optionally, wait for transaction receipt and handle it
        //const receipt = await web3.eth.getTransactionReceipt(sendTransactionHash);

        const result = `Hash: ${sendTransactionHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });

      }
      else if (func.name === "ReceiveTokens" || func.name === "MovePresaleBonus"){

        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];

        const sendData = contractInstance.methods[func.name](_amount.toString()).encodeABI();

        // Prepare transaction parameters
        const transactionParameters = {
          from: walletAddress,
          to: masterControllerAddress,
          data: sendData,
        };

        let gasEstimate = await web3.eth.estimateGas(transactionParameters);
        let gasPrice = await web3.eth.getGasPrice();

        let gasFee = gasEstimate * 3

        // Now, you can construct and send your transaction with the calculated gas values
        const newTxParameters = {
          ...transactionParameters,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
        };

        //Send to user via eth_sendTransaction request with parameters
        const transactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newTxParameters]
        });

        // Handle the transaction hash or receipt as needed
        // Optionally, wait for transaction receipt and handle it
        //const receipt = await web3.eth.getTransactionReceipt(sendTransactionHash);

        const result = `Hash: ${transactionHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === "ChangePresalePhase"){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        const _phase = inputValues['_phase'];

        const intPhase = web3.utils.toBN(_phase);

        const sendData = contractInstance.methods[func.name](intPhase).encodeABI();

        const sendTransactionObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          gas: web3.utils.toHex(1300000),
          gasPrice: web3.utils.toHex(web3.utils.toWei('30', 'gwei')),
          data: sendData,
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const sendTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [sendTransactionObject]
        });

        // Handle the transaction hash or receipt as needed
        // Optionally, wait for transaction receipt and handle it
        //const receipt = await web3.eth.getTransactionReceipt(sendTransactionHash);

        const result = `Hash: ${sendTransactionHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });

      }

      else if (func.name === 'ReceivePresaleFunds'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];
        const _token = inputValues['_token'];

        const sendData = contractInstance.methods[func.name](_amount.toString(), _token).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === 'SetPresalePrices'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        // Retrieve the _amount input from inputValues
        const _amountSWTH = inputValues['_amountMATIC'];
        const _amountWBTC = inputValues['_amountWBTC'];
        const _amountUSC = inputValues['_amountUSC'];

        const sendData = contractInstance.methods[func.name](_amountSWTH.toString(), _amountWBTC.toString(), _amountUSC.toString()).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === 'AlterAdministrator'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        const _position = inputValues['_position'];
        const _address = inputValues['_address'];

        const sendData = contractInstance.methods[func.name](_position.toString(), _address).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === 'Airdrop'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);

        // Retrieve the _amount input from inputValues
        const _allotment = inputValues['_allotment'];
        const _address = inputValues['_address'];

        const sendData = contractInstance.methods[func.name](_address, _allotment.toString()).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else {
        console.log("SOMETHIGN ELSE")
      }
    } catch (error) {
      console.error(`Error calling ${func.name}: ${error.message}`);
    }
  };

  const handleFixFunctionCall = async (func) => {
    let web3 = new Web3(polygonMainUrl);

    try {
      if (func.stateMutability === 'view' || func.stateMutability === 'pure') {

        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);
        // Prepare the arguments for the function call
        let argValues = [];
        if (func.inputs && func.inputs.length > 0) {
          argValues = func.inputs.map(input => inputValues[input.name] || '');
        }

        // Call the function
        const result = await contractInstance.methods[func.name](...argValues).call();

        setViewData({
          ...viewData,
          [func.name]: result,
        });

      } else if (func.name === "deposit"){
        web3 = new Web3(polygonMainUrl);
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const tokenContract = new web3.eth.Contract(standardERC20ABI, siteTokenMATIC);

        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);
        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];

        const weiValue = web3.utils.toWei(_amount.toString()); // Equivalent to 'ether'
        const approveData = tokenContract.methods.approve(masterControllerFixAddress, weiValue).encodeABI();

        const approveTransactionObject = {
          from: walletAddress,
          to: tokenContract.options.address,
          gas: web3.utils.toHex(6000000),
          gasPrice: web3.utils.toHex(web3.utils.toWei('30', 'gwei')),
          data: approveData,
        };
        const approveTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [approveTransactionObject]
        });

        const sendData = contractInstance.methods[func.name](weiValue).encodeABI();

        const sendTransactionObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerFixAddress, // Contract address
          gas: web3.utils.toHex(1300000),
          gasPrice: web3.utils.toHex(web3.utils.toWei('30', 'gwei')),
          data: sendData,
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const sendTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [sendTransactionObject]
        });

        // Handle the transaction hash or receipt as needed
        // Optionally, wait for transaction receipt and handle it
        //const receipt = await web3.eth.getTransactionReceipt(sendTransactionHash);

        const result = `Hash: ${sendTransactionHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });

      }

      else if (func.name === 'ReceivePresaleFunds'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        // Retrieve the _amount input from inputValues
        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];
        const _token = inputValues['_token'];

        const sendData = contractInstance.methods[func.name](_amount.toString(), _token).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerFixAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === 'SetPresalePrices'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        // Retrieve the _amount input from inputValues
        // Retrieve the _amount input from inputValues
        const _amountSWTH = inputValues['_amountMATIC'];
        const _amountWBTC = inputValues['_amountWBTC'];
        const _amountUSC = inputValues['_amountUSC'];

        const sendData = contractInstance.methods[func.name](_amountSWTH.toString(), _amountWBTC.toString(), _amountUSC.toString()).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerFixAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === "MoveEVOW"){

        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        // Retrieve the _amount input from inputValues
        const _amount = inputValues['_amount'];

        const sendData = contractInstance.methods[func.name](_amount.toString()).encodeABI();

        // Prepare transaction parameters
        const transactionParameters = {
          from: walletAddress,
          to: masterControllerFixAddress,
          data: sendData,
        };

        let gasEstimate = await web3.eth.estimateGas(transactionParameters);
        let gasPrice = await web3.eth.getGasPrice();

        let gasFee = gasEstimate * 3

        // Now, you can construct and send your transaction with the calculated gas values
        const newTxParameters = {
          ...transactionParameters,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
        };

        //Send to user via eth_sendTransaction request with parameters
        const transactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newTxParameters]
        });

        // Handle the transaction hash or receipt as needed
        // Optionally, wait for transaction receipt and handle it
        //const receipt = await web3.eth.getTransactionReceipt(sendTransactionHash);

        const result = `Hash: ${transactionHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else if (func.name === 'AlterAdministrator'){
        // Handle other types of functions (payable, nonpayable, external)
        // You can send transactions or perform other actions here
        const contractInstance = new web3.eth.Contract(masterControllerFixABI, masterControllerFixAddress);

        // Retrieve the _amount input from inputValues
        const _position = inputValues['_position'];
        const _address = inputValues['_address'];

        const sendData = contractInstance.methods[func.name](_position.toString(), _address).encodeABI();

        const masterTxObject = {
          from: walletAddress, // Set the sender's address
          to: masterControllerFixAddress, // Contract address
          data: sendData,
        };

        let gasPrice = await web3.eth.getGasPrice();
        let gasEstimate = await web3.eth.estimateGas(masterTxObject);
        let gasFee = gasEstimate * 3;

        const newMasterTransaction = {
          ...masterTxObject,
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
        };

        // Send transaction
        //Send to user via eth_sendTransaction request with parameters
        const txHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [newMasterTransaction]
        });

        // Handle the transaction hash or receipt
        const result = `Hash: ${txHash}`

        setViewData({
          ...viewData,
          [func.name]: result,
        });
      }

      else {
        console.log("SOMETHIGN ELSE")
      }
    } catch (error) {
      console.error(`Error calling ${func.name}: ${error.message}`);
    }
  };

  return (
    <div>
    <Container>
      {web3 && (
          <Row>
            <Col style={outlinedColumnStyle}>
            <h3>Admin Panel</h3>

              {masterControllerABI
                .filter(
                  (func) =>
                    (func.stateMutability === 'view' ||
                      func.stateMutability === 'external' ||
                      func.stateMutability === 'payable' ||
                      func.stateMutability === 'nonpayable') &&
                    func.name !== '__init__' &&
                    func.type !== 'constructor'
                )
                .map((func, index) => (
                  <div key={index} className="function-container">
                    <Button
                      variant={
                        func.stateMutability === 'view'
                          ? 'info'
                          : func.stateMutability === 'payable'
                          ? 'success'
                          : 'primary'
                      }
                      style={{ marginBottom: '10px', marginRight: '10px' }}
                      onClick={() => handleFunctionCall(func)}
                    >
                      {func.name}
                    </Button>
                    {func.inputs.length > 0 &&
                      func.inputs.map((input, i) => (
                        <input
                          key={i}
                          type="text"
                          placeholder={input.name}
                          value={inputValues[input.name] || ''}
                          onChange={(e) => handleInputChange(input.name, e.target.value)}
                        />
                      ))}
                    {viewData[func.name] && (
                      <div className="view-data">
                        {func.name} Result: {viewData[func.name]}
                      </div>
                    )}
                  </div>
                ))}
            </Col>
          </Row>
      )}
    </Container>
    <Container>
      {web3 && (
          <Row>
            <Col style={outlinedColumnStyle}>
            <h3>Admin Panel</h3>

              {masterControllerFixABI
                .filter(
                  (func) =>
                    (func.stateMutability === 'view' ||
                      func.stateMutability === 'external' ||
                      func.stateMutability === 'payable' ||
                      func.stateMutability === 'nonpayable') &&
                    func.name !== '__init__' &&
                    func.type !== 'constructor'
                )
                .map((func, index) => (
                  <div key={index} className="function-container">
                    <Button
                      variant={
                        func.stateMutability === 'view'
                          ? 'info'
                          : func.stateMutability === 'payable'
                          ? 'success'
                          : 'primary'
                      }
                      style={{ marginBottom: '10px', marginRight: '10px' }}
                      onClick={() => handleFixFunctionCall(func)}
                    >
                      {func.name}
                    </Button>
                    {func.inputs.length > 0 &&
                      func.inputs.map((input, i) => (
                        <input
                          key={i}
                          type="text"
                          placeholder={input.name}
                          value={inputValues[input.name] || ''}
                          onChange={(e) => handleFixInputChange(input.name, e.target.value)}
                        />
                      ))}
                    {viewData[func.name] && (
                      <div className="view-data">
                        {func.name} Result: {viewData[func.name]}
                      </div>
                    )}
                  </div>
                ))}
            </Col>
          </Row>
      )}
    </Container>
    </div>
  );
};

export default AdminPanel;
