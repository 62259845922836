import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Roadmap = () => {
  // Use white-space: pre-line; to preserve line breaks
 const textWithLineBreaks = { whiteSpace: 'pre-line' };

 const outlinedColumnStyle = {
   border: '2px solid #000000', // Adjust border style as needed
   padding: '20px', // Add padding for spacing
   borderRadius: '10px', // Add border radius for rounded corners
   margin: '6px', // Add margin on all sides
   backgroundColor: '#FEAE67',
 };

  return (
    <Container>
      <Col style={outlinedColumnStyle}>
      <h1>Road Map</h1>
      <Row>
        <Col>
        <div className = 'custom-roadmap' style = {{ height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
          <h4>Block Talk - Encrypted Messaging on Chain</h4>
          <div style={{ width: '100px', margin: 'auto' }}>
            <CircularProgressbar
              value='80'
              text={`80%`}
              styles={buildStyles({
                textColor: '#000000',
                pathColor: '#FECEA3',
                trailColor: `rgba(254, 119, 0, ${80 / 100})`,
              })}
            />
          </div>
        </div>
        </Col>

        <Col>
          <div className = 'custom-roadmap' style = {{ height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
            <h4>IDO - Initial Distribution Offering Smart Contracts</h4>
            <div style={{ width: '100px', margin: 'auto' }}>
              <CircularProgressbar
                value='20'
                text={`20%`}
                styles={buildStyles({
                  textColor: '#000000',
                  pathColor: '#FECEA3',
                  trailColor: `rgba(254, 119, 0, ${20 / 100})`,
                })}
              />
            </div>
          </div>
        </Col>

        <Col>
          <div className = 'custom-roadmap' style = {{ height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
            <h4>Record Storage</h4>
            <div style={{ width: '100px', margin: 'auto' }}>
              <CircularProgressbar
                value='65'
                text={`65%`}
                styles={buildStyles({
                  textColor: '#000000',
                  pathColor: '#FECEA3',
                  trailColor: `rgba(254, 119, 0, ${65 / 100})`,
                })}
              />
            </div>
          </div>
        </Col>

      </Row>
        <Row>

          <Col>
          <div className = 'custom-roadmap' style = {{height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
            <h4>Dispute Resolution Contracts</h4>
            <div style={{ width: '100px', margin: 'auto' }}>
              <CircularProgressbar
                value='5'
                text={`5%`}
                styles={buildStyles({
                  textColor: '#000000',
                  pathColor: '#FECEA3',
                  trailColor: `rgba(254, 119, 0, ${5 / 100})`,
                })}
              />
            </div>
          </div>
          </Col>

          <Col>
            <div className = 'custom-roadmap' style = {{height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
              <h4>Supply Chain Management Contracts</h4>
              <div style={{ width: '100px', margin: 'auto' }}>
                <CircularProgressbar
                  value='5'
                  text={`5%`}
                  styles={buildStyles({
                    textColor: '#000000',
                    pathColor: '#FECEA3',
                    trailColor: `rgba(254, 119, 0, ${5 / 100})`,
                  })}
                />
              </div>
            </div>
          </Col>

          <Col>
            <div className = 'custom-roadmap' style = {{height: '220px', border: '2px solid #000000', backgroundColor: '#FFFFFF', marginBottom: '8px', borderRadius: '10px'}} >
              <h4>Data Exchange</h4>
              <div style={{ width: '100px', margin: 'auto' }}>
                <CircularProgressbar
                  value='5'
                  text={`5%`}
                  styles={buildStyles({
                    textColor: '#000000',
                    pathColor: '#FECEA3',
                    trailColor: `rgba(254, 119, 0, ${5 / 100})`,
                  })}
                />
              </div>
            </div>
          </Col>
        </Row>
        </Col>
    </Container>
  );
};

export default Roadmap;
